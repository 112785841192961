import { CONSTANTS } from "../config/constants";
import validator from 'validator';

export function envoyer_courriel_contact(state, setState)
{
  if(validator.isEmpty(state.courriel.prenom))
    {
        setState({
            ...state,
            erreurCourriel: "Veuillez remplir le prénom !"
        })
        window.scrollTo(0,0)
        return;
    }
    if(!validator.isLength(state.courriel.prenom,{ min:1, max:255 }))
    {
        setState({
            ...state,
            erreurCourriel: "Veuillez garder votre prénom en dessous de 255 caractères !"
        })
        window.scrollTo(0,0)
        return;
    }
    if(validator.isEmpty(state.courriel.nom))
    {
        setState({
            ...state,
            erreurCourriel: "Veuillez remplir le nom !"
        })
        window.scrollTo(0,0)
        return;
    }
    if(!validator.isLength(state.courriel.nom,{ min:1, max:255 }))
    {
        setState({
            ...state,
            erreurCourriel: "Veuillez garder votre nom en dessous de 255 caractères !"
        })
        window.scrollTo(0,0)
        return;
    }
    if(validator.isEmpty(state.courriel.courriel))
    {
        setState({
            ...state,
            erreurCourriel: "Veuillez remplir le courriel !"
        })
        window.scrollTo(0,0)
        return;
    }
    if(!validator.isEmail(state.courriel.courriel))
    {
        setState({
            ...state,
            erreurCourriel: "Veuillez remplir un courriel de la forme johndoe@domaine.com !"
        })
        window.scrollTo(0,0)
        return;
    }
    if(validator.isEmpty(state.courriel.numero_telephone))
    {
        setState({
            ...state,
            erreurCourriel: "Veuillez remplir le numéro de téléphone !"
        })
        window.scrollTo(0,0)
        return;
    }
    if(!validator.isLength(state.courriel.numero_telephone, { min: 12, max: 15}))
    {
        setState({
            ...state,
            erreurCourriel: "Veuillez remplir un numéro de téléphone entre 12 et 15 caractères sous la forme suivante 1-999-999-9999 !"
        })
        window.scrollTo(0,0)
        return;
    }
    if(!CONSTANTS.regexEmail.test(state.courriel.numero_telephone))
    {
        setState({
            ...state,
            erreurCourriel: "Veuillez remplir un numéro de téléphone sous la forme suivante 1-999-999-9999 !"
        })
        window.scrollTo(0,0)
        return;
    }
    if(validator.isEmpty(state.courriel.sujet))
    {
        setState({
            ...state,
            erreurCourriel: "Veuillez remplir le sujet !"
        })
        window.scrollTo(0,0)
        return;
    }
    if(!validator.isLength(state.courriel.sujet,{ min:1, max:255 }))
    {
        setState({
            ...state,
            erreurCourriel: "Veuillez garder votre sujet en dessous de 255 caractères !"
        })
        window.scrollTo(0,0)
        return;
    }
    if(validator.isEmpty(state.courriel.message))
    {
        setState({
            ...state,
            erreurCourriel: "Veuillez remplir le sujet !"
        })
        window.scrollTo(0,0)
        return;
    }

    fetch(CONSTANTS.nomDomaineApi + "/courriel/contact", {
        headers: {
          'Content-Type': 'application/json',
          'Origin':"jessielphotographe.com"
        },
        body: JSON.stringify({
            ...state.courriel
        }),
        method: "POST",
        redirect: 'follow'
      })
        .then(res => res.json())
        .then(
          (result) => {
            setState({
                ...state,
                erreurCourriel:"Votre courriel a bel et bien été envoyé !",
                courriel: {
                    prenom:"",
                    nom:"",
                    courriel:"",
                    numero_telephone:"",
                    sujet:"",
                    message:"",
                    est_volontaire_infolettre:false
                }
            })
            window.scrollTo(0,0)
          },
          (error) => {
            console.log(error)
            setState({
                ...state,
                erreurCourriel:"Une erreur est survenue lors de l'envoi du courriel"
            })
            window.scrollTo(0,0)
          }
        )
}