import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CONSTANTS } from '../../config/constants';
import styles from './texte-biographie.module.css';

export default function TexteBiographie() {
    return (
        <Row className={styles.jl_conteneur_texte_biographie}>
            <Col lg={3} xs="1"></Col>
            <Col lg={3} xs="10">
                <img
                    className={'img-fluid ' + styles.image_jessie_biographie}
                    src={ CONSTANTS.nomDomaineApi + "/images/divers/jessie-lavertu-proprietaire-de-jessiel-photographe.jpg" }
                    alt="Propriétaire de JessieL Photographe, Mme. Lavertu."
                />
            </Col>
            <Col className='d-lg-none' xs="1"></Col>
            <Col className='d-lg-none' xs="1"></Col>
            <Col lg={3} xs="10">
                <p className={styles.jl_texte_biographie}>Je me nomme Jessie Lavertu et je suis diplômée en photographie. Depuis toute petite, je suis passionnée par tout ce qui touche le domaine des arts. J'ai été figurante dans quelques émissions et j'ai un certain talent en dessin de portrait. J'ai d'ailleurs fait mon secondaire en Arts visuels et approche multimédia.</p>
                <p className={styles.jl_texte_biographie}>Déjà à cet instant, je commençais à faire de la photographie et a être captivée par ce travail. Je n'avais par contre aucune idée que la vie me mènerait vers cette sphère. C'est en secondaire 5 que j'ai choisi de faire mon cours en photo.</p>
                <p className={styles.jl_texte_biographie}>Ensuite, plus le cours avançait et plus je développais ma créativité et ma technique, c'est à ce moment que j'ai su que j'étais exactement à ma place et que je voulais faire ça dans la vie. J'ai executé un stage dans ce domaine avec 2 photographes de Lapresse, sois Édouart Plante-Peltier ainsi que Patrick Sanfaçon et un autre avec Marc-antoine Charlebois, j'ai énormément appris. Aujourd'hui je me spéciale dans le portrait, la mode et l'événementiel.</p>
                <p className={styles.jl_texte_biographie}>Être photographe est un merveilleux métier qui en vaut la peine.</p>
                <br/>
                <footer className={styles.jl_texte_biographie + " blockquote-footer"}>Jessie Lavertu, Priopriétaire de <cite title="JessieL Photographe">JessieL Photographe.</cite></footer>
            </Col>
            <Col lg={3} xs="1"></Col>
        </Row>
    );
  }