import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Image from '../commun/image';
import GwAlert from '../commun/gw-alert';
import { obtenir_les_images_album } from '../../services/photoService';
import styles from "./liste-photo.module.css";

export default function ListePhoto(props) {
    const [state, setState] = useState({
        listePhoto: null
    })

    useEffect(() => {
        !state.listePhoto && obtenir_les_images_album(props.albumUUID, state, setState);
    }, [state, props.albumUUID])

    return (
            <div>
                {
                    state.listePhoto &&
                    state.listePhoto.length === 0 &&
                    <Row>
                        <Col xs={4}>
                            <GwAlert variant='warning'>Aucune photo à afficher pour cet album.</GwAlert>
                        </Col>
                    </Row>
                }
                {
                    !props.avecRow &&
                    <Row className='text-center'>
                        {
                            state.listePhoto && 
                            state.listePhoto.length > 0 &&
                            state.listePhoto
                            .slice(0, 10)
                            .map((image) => {
                                return (
                                    <Image key={image.uuid} uuid={image.uuid} extension={image.extension} type_image={image.type_photo_id} src={image.nom}></Image>
                                )
                            })
                        }
                    </Row>
                }
                {
                    props.avecRow &&
                    <Row className={styles.conteneur_photo}>
                        {
                            state.listePhoto && 
                            state.listePhoto.length > 0 &&
                            state.listePhoto
                            .slice(0, 10)
                            .map((image) => {
                                return (
                                    <Image key={image.uuid} uuid={image.uuid} extension={image.extension} type_image={image.type_photo_id} src={image.nom}></Image>
                                )
                            })
                        }
                    </Row>
                }
            </div>
    );
  }