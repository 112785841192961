export function obtenirToken()
{
    return localStorage.getItem("token")
}

export function sauvegarderToken(token)
{
    return localStorage.setItem("token", token)
}

export function supprimerToken()
{
    localStorage.removeItem("token")
}