import { CONSTANTS } from "../config/constants"
import { obtenirToken } from "../utilitaires/utilToken"

export function obtenir_tous_les_types_photo(state, setState)
{
  let token = obtenirToken()
  fetch(CONSTANTS.nomDomaineApi + "/types-photo", {
      headers: {
        'Content-Type': 'application/json',
        'Origin':"jessielphotographe.com",
        'Authorization': 'Bearer ' + token
      },
      method: "GET"
    })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.contenu)
          {
            setState({
                ...state,
                listeTypePhoto: result.contenu.liste_types_photo
            })
          }
        },
        (error) => {
          console.log(error)
        }
      )
}