import { Link, useSearchParams } from 'react-router-dom';
import { rechercher_site_web } from '../../services/rechercheService';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import styles from "./resultat-recherche.module.css";
import GwAlert from '../commun/gw-alert';

export default function ResultatsRecherche() {

    const [searchParams] = useSearchParams();
    const [state, setState]  = useState({
        listeResultatRecherche: null
    });

    useEffect(() => {
        !state.listeResultatRecherche && rechercher_site_web(searchParams.get("s"), state, setState)
    }, [state, searchParams])

    return (
        <>
            {
                state.listeResultatRecherche &&
                state.listeResultatRecherche.length > 0 &&
                state.listeResultatRecherche.map((resultat) => {
                    return(
                    <Row className={styles.resultat_recherche}>
                        <Col xs={4}></Col>
                        <Col xs={4}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>{resultat.nom}</Card.Title>
                                    <Card.Text>{resultat.description}</Card.Text>
                                    <Link to={resultat.uuid}>
                                        <Button className={styles.bouton_resultat_recherche} variant="primary">Voir la page</Button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={4}></Col>
                    </Row>
                    )
                })
                }
                {
                state.listeResultatRecherche &&
                state.listeResultatRecherche.length === 0 &&
                <Row>
                    <Col xs={4}></Col>
                    <Col xs={4}>
                        <GwAlert variant="warning">Aucun résultat pour cette recherche.</GwAlert>
                    </Col>
                    <Col xs={4}></Col>
                </Row>
            }
        </>
    );
  }