import { CONSTANTS } from "../config/constants";
import { obtenirToken, sauvegarderToken, supprimerToken } from "../utilitaires/utilToken";

export function connexion(navigate, state, setState)
{
    if(!state.identifiantsConnexion.courriel)
    {
      setState({
        ...state,
        erreurConnexion: "Veuillez entrer votre courriel !"
      })
      return;
    }
    if(!state.identifiantsConnexion.mot_de_passe)
    {
      setState({
        ...state,
        erreurConnexion: "Veuillez entrer votre mot de passe !"
      })
      return;
    }

    fetch(CONSTANTS.nomDomaineApi + "/se-connecter", {
        headers: {
          'Content-Type': 'application/json',
          'Origin':"jessielphotographe.com"
        },
        method: "POST",
        body: JSON.stringify(state.identifiantsConnexion),
        redirect: 'follow'
      })
        .then(res => res.json())
        .then(
          (result) => {
            if(result.contenu)
            {
               sauvegarderToken(result.contenu.token)
               navigate("/bibliotheque-image")
            }

            setState({
              ...state,
              erreurConnexion: "Vos identifiants n'ont pas été reconnus !"
            })
            window.scrollTo(0,0)
          },
          (error) => {
            console.log(error)
            setState({
              ...state,
              erreurConnexion: "Vos identifiants n'ont pas été reconnus !"
            })
            window.scrollTo(0,0)
          }
        )
}

export function verifier_token(navigate, location)
{
  let token = obtenirToken();

  if(!token)
  {
    navigate("/connexion")
    supprimerToken()
  }

  fetch(CONSTANTS.nomDomaineApi + "/verifier-token", {
    headers: {
      'Content-Type': 'application/json',
      'Origin':"jessielphotographe.com",
      "Authorization": "Bearer " + token
    },
    method: "POST"
  })
    .then(
      async (response) => {
        if(response.status !== 200)
        {
          navigate("/connexion")
          supprimerToken()
        }
      },
      (error) => {
        console.log(error)
        navigate("/connexion")
        supprimerToken()
      }
    )
}

export function envoyer_courriel_reinitialisation(state, setState)
{
  if(!state.courriel)
  {
    setState({
      ...state,
      erreurMotDePasseOublier: {
        type: "warning",
        texte:"Veuillez entrer votre courriel."
      }
    })
    return;
  }

  console.log(state)

  fetch(CONSTANTS.nomDomaineApi + "/courriel/renitialisation", {
    headers: {
      'Content-Type': 'application/json',
      'Origin':"jessielphotographe.com"
    },
    body: JSON.stringify({
      courriel: state.courriel,
      recaptcha_token: state.recaptcha_token
    }),
    method: "POST"
  })
    .then(
      async (response) => {
        if(response.status === 200)
        {
           setState({
              ...state,
              etapeActuelle: 2,
              erreurMotDePasseOublier: {
                type: "success",
                texte:"Veuillez entrer le code à 6 chiffre qui vous a été envoyé par courriel."
              }
          })
        }
        else
        {
          setState({
            ...state,
            erreurMotDePasseOublier: {
              type: "danger",
              texte:"Une erreur inattendue est survenue."
            }
          })
        }
      },
      (error) => {
        console.log(error)
        setState({
          ...state,
          erreurMotDePasseOublier: {
            type: "danger",
            texte:"Une erreur inattendue est survenue."
          }
        })
      }
    )
}

export function verifier_code_renitialisation(state, setState) {
  if(!state.codeRenitialisation)
  {
    setState({
      ...state,
      erreurMotDePasseOublier: {
        type: "warning",
        texte:"Veuillez entrer le code que vous avez reçu."
      }
    })
    return;
  }

  fetch(CONSTANTS.nomDomaineApi + "/verifier-code-reinitialisation", {
    headers: {
      'Content-Type': 'application/json',
      'Origin':"jessielphotographe.com"
    },
    body: JSON.stringify({
      courriel: state.courriel,
      code_reinitialisation: state.codeRenitialisation
    }),
    method: "POST"
  })
    .then(
      async (response) => {
        if(response.status === 200)
        {
           setState({
              ...state,
              etapeActuelle: 3,
              erreurMotDePasseOublier: {
                type: "success",
                texte:"Veuillez changer votre mot de passe."
              }
          })
        }
        else
        {
          setState({
            ...state,
            erreurMotDePasseOublier: {
              type: "danger",
              texte:"Une erreur inattendue est survenue."
            }
          })
        }
      },
      (error) => {
        console.log(error)
        setState({
          ...state,
          erreurMotDePasseOublier: {
            type: "danger",
            texte:"Une erreur inattendue est survenue."
          }
        })
      }
    )
}

export function changer_mon_mot_de_passe(state, setState) {
  if(!state.mot_de_passe)
  {
    setState({
      ...state,
      erreurMotDePasseOublier: {
        type: "warning",
        texte:"Veuillez entrer votre mot de passe."
      }
    })
    return;
  }
  if(!state.nouveau_mot_de_passe)
  {
    setState({
      ...state,
      erreurMotDePasseOublier: {
        type: "warning",
        texte:"Veuillez entrer à nouveau votre mot de passe."
      }
    })
    return;
  }
  if(state.mot_de_passe !== state.nouveau_mot_de_passe)
  {
    setState({
      ...state,
      erreurMotDePasseOublier: {
        type: "warning",
        texte:"Veuillez faire en sorte que vos mot de passe concordent."
      }
    })
    return;
  }

  fetch(CONSTANTS.nomDomaineApi + "/changer-mot-de-passe", {
    headers: {
      'Content-Type': 'application/json',
      'Origin':"jessielphotographe.com"
    },
    body: JSON.stringify({
      courriel: state.courriel,
      mot_de_passe: state.mot_de_passe,
      code_reinitialisation: state.codeRenitialisation
    }),
    method: "POST"
  })
    .then(
      async (response) => {
        if(response.status === 200)
        {
           setState({
              ...state,
              etapeActuelle: 4,
              erreurMotDePasseOublier: {
                type: "success",
                texte:"Vous avez changé votre mot de passe avec succès."
              }
          })
        }
        else
        {
          setState({
            ...state,
            erreurMotDePasseOublier: {
              type: "danger",
              texte:"Une erreur inattendue est survenue."
            }
          })
        }
      },
      (error) => {
        console.log(error)
        setState({
          ...state,
          erreurMotDePasseOublier: {
            type: "danger",
            texte:"Une erreur inattendue est survenue."
          }
        })
      }
    )
}