import React, { useEffect } from 'react';
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import TitrePage from '../composants/commun/titre-page';
import { obtenirMeta } from '../utilitaires/utilMeta';
import PiedPage from '../composants/commun/pied-page';
import ZoneDepotImage from '../composants/bibliotheque_image/zone-depot-image';
import ListeImage from '../composants/bibliotheque_image/liste-image';
import { useState } from 'react';
import { obtenir_toutes_les_images } from '../services/photoService';
import { useLocation, useNavigate } from 'react-router-dom';
import { CONSTANTS } from '../config/constants';
import { verifier_token } from '../services/authentificationService';

export default function PageBibliothequeImage() {
    const meta = obtenirMeta(window.location)
    const [state, setState]  = useState({
      listeImage: null
    });
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      verifier_token(navigate, location);
      const myInterval = setInterval(verifier_token, CONSTANTS.tempsRequisAvantVerificationCredentiel, navigate, location);
      !state.listeImage && obtenir_toutes_les_images(state, setState)
      return () => {
        clearInterval(myInterval)
      }
    }, [state, navigate, location])

    return (
      <DocumentMeta {...meta}>
        <Container fluid>
          <Navigation></Navigation>
          <TitrePage titre="Bibliothèque image"></TitrePage>
          <ZoneDepotImage setStateListeImage={setState}></ZoneDepotImage>
          <ListeImage listeImage={state.listeImage} setStateListeImage={setState}></ListeImage>
          <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }