import React, { useEffect, useState } from 'react';
import { Button, Form, FormControl, FormGroup, FormLabel, Modal } from 'react-bootstrap';
import { CONSTANTS } from '../../config/constants';
import { modifier_image, obtenir_image_par_uuid, supprimer_image } from '../../services/photoService';
import styles from "./fenetre-image.module.css";
import { obtenir_tous_les_albums } from '../../services/albumService';
import { obtenir_tous_les_types_photo } from '../../services/typePhotoService';
import GwAlert from '../commun/gw-alert';

export default function FenetreImage(props) {

    const [state, setState] = useState({
        image: null,
        listeAlbums: null,
        listeTypePhoto: null,
        erreurModificationImage: {
            type: "",
            texte: ""
        }
    })

    useEffect(() => {
        !state.image && props.imageUUID && obtenir_image_par_uuid(props.imageUUID, state, setState)
        !state.listeAlbums && obtenir_tous_les_albums(state, setState)
        !state.listeTypePhoto && obtenir_tous_les_types_photo(state, setState)
    }, [state, props.imageUUID])

    return (
        <Modal 
            show={props.estAffiche} 
            onHide={() => {
                setState({
                    ...state,
                    image: null
                })
                props.gererFermeture()
            }}
        >
            <Modal.Header closeButton>
                {
                    state.image &&
                    <Modal.Title>{state.image.nom ? state.image.nom : "Aucun nom associé"}</Modal.Title>
                }
            </Modal.Header>
            <Modal.Body className='text-center'>
                {
                    state.image &&
                    state.listeAlbums &&
                    <>
                        <img 
                            className={'img-fluid ' + styles.image}
                            src={CONSTANTS.dossierImage + state.image.nom_fichier}
                            alt={state.image.description}
                        />
                        {
                            state.erreurModificationImage.texte &&
                            <GwAlert variant={state.erreurModificationImage.type}>{state.erreurModificationImage.texte}</GwAlert>
                        }
                        <Form>
                                <FormGroup className="mb-3">
                                    <Form.Label className={styles.jl_titre_champ_donnee_form_connexion}>Nom de l'image *</Form.Label>
                                    <Form.Control 
                                        className={styles.jl_champ_donnee_form_connexion}
                                        onChange={(event) => {
                                            setState({
                                                ...state,
                                                image: {
                                                    ...state.image,
                                                    nom: event.target.value
                                                },
                                                erreurModificationImage: {
                                                    type: "",
                                                    texte: ""
                                                }
                                            }) 
                                        }}
                                        value={state.image.nom ?? ""}
                                        type="text" 
                                        placeholder="Nom de l'image" 
                                    />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <FormLabel>Description de l'image</FormLabel>
                                    <FormControl 
                                        as="textarea"
                                        type='text'
                                        rows={3}
                                        value={state.image.description ?? ""}
                                        onChange={(event) => {
                                            setState({
                                                ...state,
                                                image: {
                                                    ...state.image,
                                                    description: event.target.value
                                                },
                                                erreurModificationImage: {
                                                    type: "",
                                                    texte: ""
                                                }
                                            }) 
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Album de l'image</FormLabel>
                                    <FormControl 
                                        as="select"
                                        onChange={(event) => {
                                            setState({
                                                ...state,
                                                image: {
                                                    ...state.image,
                                                    album_id: event.target.value
                                                },
                                                erreurModificationImage: {
                                                    type: "",
                                                    texte: ""
                                                }
                                            }) 
                                        }}
                                        value={state.image.album_id}
                                    >
                                        {
                                            state.listeAlbums &&
                                            state.listeAlbums.length > 0 &&
                                            state.listeAlbums.map((album) => {
                                                return (
                                                    <option key={album.id} value={album.id}>{album.nom}</option>
                                                )
                                            })
                                        }
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Type de l'image</FormLabel>
                                    <FormControl 
                                        as="select"
                                        onChange={(event) => {
                                            setState({
                                                ...state,
                                                image: {
                                                    ...state.image,
                                                    type_photo_id: event.target.value
                                                },
                                                erreurModificationImage: {
                                                    type: "",
                                                    texte: ""
                                                }
                                            }) 
                                        }}
                                        value={state.image.type_photo_id}
                                    >
                                        {
                                            state.listeTypePhoto &&
                                            state.listeTypePhoto.length > 0 &&
                                            state.listeTypePhoto.map((typePhoto) => {
                                                return (
                                                    <option key={typePhoto.id} value={typePhoto.id}>{typePhoto.nom}</option>
                                                )
                                            })
                                        }
                                    </FormControl>
                                </FormGroup>
                        </Form>
                    </>
                    
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={() => {
                    modifier_image(props.imageUUID, state, setState);
                }}>Modifier</Button>
                <Button variant="danger" onClick={() => {
                    supprimer_image(props.imageUUID, props.setStateListeImage);
                    setState({
                        ...state,
                        image: null
                    })
                    props.gererFermeture();
                }}>Supprimer</Button>
                <Button variant="secondary" onClick={() => {
                    setState({
                        ...state,
                        image: null
                    })
                    props.gererFermeture();
                }}>Fermer</Button>
            </Modal.Footer>
        </Modal>
    );
  }