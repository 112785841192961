import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './video-biographie.module.css';

export default function VideoBiographie() {
    return (
        <Row>
            <Col lg="3" xs="1"></Col>
            <Col lg="6" xs="10" className="text-center">
                <div className={styles.conteneur_video_biographie}>
                    <iframe 
                        title='Video de Jessie Lavertu expliquant son parcours professionnel'
                        src="https://player.vimeo.com/video/240691140?h=d334c53a24" 
                        allow="autoplay; fullscreen; picture-in-picture" 
                        allowFullScreen
                    ></iframe>
                </div>
            </Col>
            <Col lg="3" xs="1"></Col>
        </Row>
    );
  }