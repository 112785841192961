import PageAccueil from "../routes/page-accueil";
import PageBiographie from "../routes/page-biographie";
import PageConnexion from "../routes/page-connexion";
import PageNonTrouve from "../routes/page-non-trouve";
import PagePortfolio from "../routes/page-portfolio";
import PageTarifs from "../routes/page-tarifs";
import PageNousJoindre from "../routes/page-nous-joindre";
import PageImage from '../routes/page-image';
import PageAlbum from '../routes/page-album';
import PageRecherche from '../routes/page-recherche';
import PageBibliothequeImage from "../routes/page-bibliotheque-image";
import PageMotDePasseOublier from "../routes/page-mot-de-passe-oublier";
import PageSitemap from "../routes/page-sitemap";

export const ROUTES = [
    {
        path:"/",
        element: PageAccueil,
        inSitemap: true,
        meta: {
            title: 'JessieL Photographe | Accueil',
            description: 'JessieLPhotographe est une entreprise de photographie basé à Victoriaville qui vous permettra d\'avoir des photos à votre goût.',
            canonical: 'https://jessielphotographe.com',
            meta: {
              charset: 'utf-8'
            }
        } 
    },
    {
        path:"/connexion",
        element: PageConnexion,
        inSitemap: true,
        meta: {
            title: 'JessieL Photographe | Connexion',
            description: 'Page de permettant de se connecter.',
            canonical: 'https://jessielphotographe.com/connexion',
            meta: {
              charset: 'utf-8'
            }
        } 
    },
    {
        path:"/mot-de-passe-oublier",
        element: PageMotDePasseOublier,
        inSitemap: true,
        meta: {
            title: 'JessieL Photographe | Mot de passe oublier',
            description: 'Page permettant de réinitialiser son mot de passe.',
            canonical: 'https://jessielphotographe.com/mot-de-passe-oublier',
            meta: {
              charset: 'utf-8'
            }
        } 
    },
    {
        path:"/portfolio",
        element: PagePortfolio,
        inSitemap: true,
        meta: {
            title: 'JessieL Photographe | Portfolio',
            description: 'Découvrez les photos de qualité professionnelle de l\'entreprise de photographie JessieL Photographe.',
            canonical: 'https://jessielphotographe.com/portfolio',
            meta: {
              charset: 'utf-8'
            }
        } 
    },
    {
        path:"/biographie",
        element: PageBiographie,
        inSitemap: true,
        meta: {
            title: 'JessieL Photographe | Biographie',
            description: 'Apprenez l\'histoire de la photographe qui a créer l\'entreprise de photographie de Montréal JessieL Photographe.',
            canonical: 'https://jessielphotographe.com/biographie',
            meta: {
              charset: 'utf-8'
            }
        } 
    },
    {
        path:"/tarifs",
        element: PageTarifs,
        inSitemap: true,
        meta: {
            title: 'JessieL Photographe | Tarifs',
            description: 'Découvrez les forfaits abordables permettant d\'obtenir des séances de photographie qui sauront raviver votre plaisir de se faire prendre en photo.',
            canonical: 'https://jessielphotographe.com/tarifs',
            meta: {
              charset: 'utf-8'
            }
        } 
    },
    {
        path:"/nous-joindre",
        element: PageNousJoindre,
        inSitemap: true,
        meta: {
            title: 'JessieL Photographe | Nous joindre',
            description: 'Prenez contact avec JessieL Photographe afin d\'obtenir des photos de qualité.',
            canonical: 'https://jessielphotographe.com/tarifs',
            meta: {
              charset: 'utf-8'
            }
        } 
    },
    {
        path:"/image/:imageUUID",
        element: PageImage,
        inSitemap: false,
        meta: {
            title: 'JessieL Photographe | Image non trouvé',
            description: 'L\'Image que vous rechercher n\'existe pas.',
            canonical: 'https://jessielphotographe.com/image/',
            meta: {
              charset: 'utf-8'
            }
        } 
    },
    {
        path:"/album/:albumUUID",
        element: PageAlbum,
        inSitemap: false,
        meta: {
            title: 'JessieL Photographe | Album non trouvé',
            description: 'L\'Album que vous rechercher n\'existe pas.',
            canonical: 'https://jessielphotographe.com/album/',
            meta: {
              charset: 'utf-8'
            }
        } 
    },
    {
        path:"/recherche",
        element: PageRecherche,
        inSitemap: true,
        meta: {
            title: 'JessieL Photographe | Recherche',
            description: 'Page permettant d\'effectuer une recherche sur JessieL Photographe.',
            canonical: 'https://jessielphotographe.com/recherche',
            meta: {
              charset: 'utf-8'
            }
        } 
    },
    {
        path:"/bibliotheque-image",
        element: PageBibliothequeImage,
        inSitemap: false,
        meta: {
            title: 'JessieL Photographe | Bibliothèque image',
            description: 'Permet de gérer les images du site web.',
            canonical: 'https://jessielphotographe.com/bibliotheque-image',
            meta: {
              charset: 'utf-8'
            }
        } 
    },
    {
        path:"/sitemap.xml",
        element: PageSitemap,
        inSitemap: false,
        meta: {
            title: 'JessieL Photographe | Sitemap',
            description: 'Permet de voir toutes les pages du site web.',
            canonical: 'https://jessielphotographe.com/sitemap.xml',
            meta: {
              charset: 'utf-8'
            }
        } 
    },
    {
        path:"*",
        element: PageNonTrouve,
        inSitemap: false,
        meta: {
            title: 'JessieL Photographe | Page non trouvé',
            description: 'La page que vous avez rechercher n\'existe pas.',
            meta: {
              charset: 'utf-8'
            }
        } 
    }
]