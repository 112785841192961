import React from 'react';
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import { useState } from 'react';
import PiedPage from '../composants/commun/pied-page';
import { obtenirMeta } from '../utilitaires/utilMeta';
import TitrePage from '../composants/commun/titre-page';
import { ROUTES } from '../config/routes';
import styles from "./page.module.css";
import { Col, Row } from 'react-bootstrap';

export default function PageSitemap() {
    const meta = obtenirMeta(window.location)

    const [state]  = useState({
      meta: meta
    });

    return (
        <DocumentMeta {...state.meta}>
        <Container fluid>
          <Navigation></Navigation>
          <TitrePage titre="Sitemap"></TitrePage>
          <Row className='text-center'>
            <Col md="4"></Col>
            <Col md="4">
                <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
                    {
                        ROUTES
                        .filter((route) => {
                            return route.inSitemap;
                        })
                        .map((route) => {
                            return (
                                <a className={styles.lien_sitemap} href={route.meta.canonical}>
                                    <url className={styles.element_sitemap}>
                                        <loc>{route.meta.canonical}</loc>
                                    </url>
                                </a>
                                
                            )
                        })
                    }
                </urlset>
            </Col>
            <Col md="4"></Col>
          </Row>
          
          <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }