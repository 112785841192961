import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CONSTANTS } from '../../config/constants';
import styles from './zone-accueil-a-propos.module.css';

export default function ZoneAccueilAPropos() {
    return (
        <Container>
            <Row className={styles.jl_conteneur_zone_accueil_a_propos}>
                <Col lg="1" xs="1"></Col>
                <Col lg="5" xs="5">
                    <h4 className={styles.jl_sous_titre_zone_accueil_a_propos}>JessieL Photographe, c'est quoi ?</h4>
                    <hr className={styles.jl_ligne_zone_accueil_a_propos}/>
                    <p className={styles.jl_texte_zone_accueil_a_propos}>J'ai ouvert mon entreprise le 10 septembre 2020, j'offre des services de photographie dans la Ville de Victoriaville. Je vous invite à lire ma biographie afin de connaître mieux mon parcours.</p>
                    <p className={styles.jl_texte_zone_accueil_a_propos}>- Jessie Lavertu</p>
                    <Link to="/biographie"><Button className={styles.jl_bouton_zone_accueil_a_propos}>Lire la biographie</Button></Link>
                </Col>
                <Col xs="1"></Col>
                <Col lg="3" xs="4">
                    <img 
                        className='img-fluid'
                        src={ CONSTANTS.nomDomaineApi + "/images/divers/jessie-lavertu-proprietaire-de-jessiel-photographe.jpg" }
                        alt=" Jessie Lavertu, propriétaire de JessieL Photographe."
                    />
                </Col>
                <Col lg="1" xs="1"></Col>
            </Row>
        </Container>
    );
  }