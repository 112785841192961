import React, { useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CONSTANTS } from '../../config/constants';
import { obtenir_tous_les_albums } from '../../services/albumService';
import { obtenir_image_par_uuid } from '../../services/photoService';
import { creerCleUnique } from '../../utilitaires/utilListe';
import styles from './zone-accueil-liste-album.module.css';

export default function ZoneAccueilListeAlbum() {
    const [state, setState] = useState({
        listeAlbums: []
    });
    !state.listeAlbums.length && obtenir_tous_les_albums(state, setState);
    state.listeAlbums.length && state.listeAlbums.map((album) => {
        album.image_album_uuid && obtenir_image_par_uuid(album.image_album_uuid, state, setState, "listeAlbums")
        return null
    })
    return (
        state.listeAlbums.length && <Container>
            <Row className={styles.jl_conteneur_accueil_liste_album + " text-center"}>
                <Col xs="1"></Col>
                <Col xs="10">
                    <h3 className={styles.jl_titre_accueil_liste_album}>Nos derniers albums</h3>
                    <Row>
                        {
                            state.listeAlbums.slice(0,4).map((album) => {
                                return (
                                    <Col key={creerCleUnique()} lg={3}>
                                        <Card className={styles.jl_conteneur_carte_album}>
                                            {
                                                album.image &&
                                                <Card.Img variant="top" src={ CONSTANTS.nomDomaineApi + "/images/portfolio/" + album.image.nom + "." + album.image.extension } />
                                            }
                                            <Card.Body>
                                                <Card.Title className={styles.jl_titre_carte_album}>{album.nom}</Card.Title>
                                                <Card.Text className={styles.jl_texte_carte_album}>
                                                {
                                                    album.description && album.description.slice(0,50) + "..."
                                                }
                                                </Card.Text>
                                                <Link to={"/album/" + album.uuid}><Button className={styles.jl_bouton_carte_album} variant="primary">Voir l'album</Button></Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </Col>
                <Col xs="1"></Col>
            </Row>
        </Container>
    );
  }