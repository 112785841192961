import React, { useEffect } from 'react';
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState } from 'react';
import styles from './page.module.css';
import TitrePage from '../composants/commun/titre-page';
import { useNavigate, useParams } from 'react-router-dom';
import { obtenir_image_par_uuid } from '../services/photoService';
import PiedPage from '../composants/commun/pied-page';
import { Button, Spinner } from 'react-bootstrap';
import Image from '../composants/commun/image';

function reconfigurer_meta(state, setState)
{
    setState({
        ...state,
        estPremierChargement: false,
        meta: {
            ...state.meta,
            title: 'Jessiel Photographe | ' + state.image.nom,
            description: state.image.description,
            canonical: 'https://jessielphotographe.com/image/' + state.image.uuid
        }
    })
}

export default function PageImage() {

    let { imageUUID } = useParams();

    //Création des constantes de la page connexion
    const [state, setState] = useState({
      meta: {
        title: 'Jessiel Photographe | N/D',
        description: 'N/D',
        canonical: 'https://jessielphotographe.com/image/',
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'react,meta,document,html,tags'
          }
        }
      },
      estPremierChargement: true,
      image:null
    });
    const navigate = useNavigate();

    useEffect(() => {
      !state.image && obtenir_image_par_uuid(imageUUID, state, setState)
      state.image && state.estPremierChargement && reconfigurer_meta(state, setState);
    }, [state, imageUUID])

    

    //On créer le rendu de la page connexion
    return (
      <DocumentMeta {...state.meta}>
        <Container fluid>
            <Navigation></Navigation>
            {
                state.image && 
                <>
                    <Row className='text-center'>
                      <Col md="4"></Col>
                      <Col md="4">
                        <Button
                          className={styles.bouton}
                          onClick={() => {
                            navigate(-1)
                          }}
                        >Retour à la page précédente</Button>
                      </Col>
                      <Col md="4"></Col>
                    </Row>
                    {
                      state.image.nom &&
                      <TitrePage titre={state.image.nom}></TitrePage>
                    }
                    <Row className='text-center'>
                      <Col xs="1" md="2"></Col>
                      <Image 
                        uuid={state.image.uuid} 
                        extension={state.image.extension} 
                        type_image={state.image.type_photo_id} 
                        src={state.image.nom_fichier} 
                        aucunLien={true}
                      ></Image>
                      <Col xs="1" md="2"></Col>
                      {
                        state.image.description &&
                        <>
                          <Col xs="1" md="4"></Col>
                          <Col xs="10" md="4">
                            <h2 className={styles.titre_description_image}>Description de l'image</h2>
                            <p className={styles.description_image}>{state.image.description ? state.image.description : "Aucune description associé."}</p>
                          </Col>
                          <Col xs="1" md="4"></Col>
                        </>
                      }
                      
                    </Row>
                </>
            }
            {
                !state.image && 
                <>
                    <TitrePage titre="En cours de chargement..."></TitrePage>
                    <Row>
                        <Col xs="2"></Col>
                        <Col xs="8" className="text-center">
                            <Spinner size='large'></Spinner>
                        </Col>
                        <Col xs="2"></Col>
                    </Row>
                </>
            }
            <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }