import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CONSTANTS } from '../../config/constants';
import styles from './pied-page.module.css';
import packageJSON from "../../../package.json";

export default function PiedPage() {
    return (
        <Container className={styles.jl_conteneur_pied_page}>
            <Row className="text-center">
                <Col xs="0" md={1}></Col>
                <Col md={3}>
                    <p className={styles.jl_titre_pied_page}>Notre courriel</p>
                    <a className={styles.lien_pied_page} href="mailto:info@jessielphotographe.com">info@jessielphotographe.com</a>
                </Col>
                <Col md={4}>
                    <img
                        className='img-fluid'
                        src={ CONSTANTS.nomDomaineApi + "/images/logo/logo-site-web-jessiel-photographe.png" }
                        alt="Logo de l'entreprise JessieL Photographe."
                    />
                </Col>
                <Col md={3}>
                    <p className={styles.jl_titre_pied_page}>Notre numéro de téléphone</p>
                    <a className={styles.lien_pied_page} href='tel:8198062028'>(819)-806-2028</a>
                </Col>
                <Col md={1}></Col>
            </Row>
            <Row className="text-center">
                <Col xs={12}>
                    <p className={styles.copyright}>JessieL Photographe © {new Date().getFullYear()} - Tous droits réservés. Version {packageJSON.version}. Site web créer par <a className={styles.jl_lien_createur_pied_page} href='https://greenwoodmultimedia.com'>GreenWood Multimedia</a>.</p>
                </Col>
            </Row>
        </Container>
    );
  }