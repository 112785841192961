import { CONSTANTS } from "../config/constants"

export function obtenir_avis(state, setState)
{
    fetch(CONSTANTS.nomDomaineApi + "/avis", {
      headers: {
        'Content-Type': 'application/json',
        'Origin':"jessielphotographe.com"
      },
      method: "GET",
      redirect: 'follow'
    })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.contenu)
          {
             setState({
                ...state,
                listeAvis: result.contenu.avis
            })
          }
        },
        (error) => {
          console.log(error)
        }
      )
}