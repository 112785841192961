import React from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { creerCleUnique } from '../../utilitaires/utilListe';
import FenetreImage from './fenetre-image';
import styles from './liste-image.module.css';
import { CONSTANTS } from '../../config/constants';
import GwAlert from '../commun/gw-alert';

export default function ListeImage(props) {
    
    const [state, setState] = useState({
        imageUUID: null,
        estAffiche: false
    })

    function gererFermeture()
    {
        setState({
            ...state,
            estAffiche: false,
            imageUUID: null,
        })
    }

    return (
        <>
            <FenetreImage 
                estAffiche={state.estAffiche} 
                gererFermeture={gererFermeture} 
                imageUUID={state.imageUUID} 
                setStateListeImage={props.setStateListeImage}
            ></FenetreImage>
            <Row className='text-center'>
                <Col xs={2}></Col>
                <Col xs={8}>
                    {
                        props.listeImage &&
                        props.listeImage.length > 0 &&
                        props.listeImage.map((image) => {
                            return (
                                <img 
                                    key={creerCleUnique()} 
                                    className={styles.image_bibliotheque} 
                                    src={CONSTANTS.dossierImage + image.nom_fichier}
                                    onClick={() => {
                                        setState({
                                            estAffiche: true,
                                            imageUUID: image.uuid
                                        })
                                    }}
                                    alt={image.description}
                                />
                            );
                        })
                    }
                    {
                        props.listeImage &&
                        props.listeImage.length === 0 &&
                        <GwAlert variant="warning">Aucune image dans la bibliothèque d'image.</GwAlert>
                    }
                </Col>
                <Col xs={2}></Col>
            </Row>
        </>
    );
  }