import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styles from './form-mot-de-passe-oublier.module.css';
import GwAlert from '../commun/gw-alert';
import { changer_mon_mot_de_passe, envoyer_courriel_reinitialisation, verifier_code_renitialisation } from '../../services/authentificationService';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { CONSTANTS } from '../../config/constants';

export default function FormMotDePasseOublier() {

    const [state, setState] = useState({
        etapeActuelle: 1,
        courriel: "",
        codeRenitialisation: "",
        mot_de_passe: "",
        nouveau_mot_de_passe: "",
        analyzedUserRecaptcha: false,
        recaptcha_token: "",
        erreurMotDePasseOublier: {
            type: "",
            texte: ""
        }
    })

    return (
        <>
            <Row>
                <Col xs="4"></Col>
                <Col xs="4" className="text-center">
                {
                    state.erreurMotDePasseOublier.texte && <GwAlert variant={state.erreurMotDePasseOublier.type}>{state.erreurMotDePasseOublier.texte}</GwAlert>
                }
                </Col>
                <Col xs="4"></Col>
            </Row>
            <GoogleReCaptchaProvider
                reCaptchaKey={CONSTANTS.cleRecaptcha}
                language='fr-CA'
                scriptProps={{
                    async: true
                }}
            >
                <GoogleReCaptcha onVerify={ (recaptcha_token) => {
                    if(!state.analyzedUserRecaptcha)
                    {
                        setState({
                            ...state,
                            analyzedUserRecaptcha: true,
                            recaptcha_token: recaptcha_token
                        })
                    }
                }} />
                {
                    state.etapeActuelle === 1 &&
                    <Row>
                        <Col lg="4" xs="1"></Col>
                        <Col lg="4" xs="10" className="text-center">
                            <Form onSubmit={(event) => {
                                event.preventDefault();
                                envoyer_courriel_reinitialisation(state, setState)
                            }}>
                                <Form.Group className="mb-3" controlId="hf_courriel">
                                    <Form.Label className={styles.jl_titre_champ_donnee_form_connexion}>Votre email *</Form.Label>
                                    <Form.Control 
                                        className={styles.jl_champ_donnee_form_connexion}
                                        onChange={(event) => { 
                                            event.preventDefault(); 
                                            setState({
                                                ...state,
                                                courriel: event.target.value,
                                                erreurMotDePasseOublier: {
                                                    type: "",
                                                    texte: ""
                                                }
                                            })
                                        }}
                                        value={state.courriel}
                                        type="email" 
                                        placeholder="exemple@jessielphotographe.com" 
                                    />
                                </Form.Group>
                                <Button className={styles.jl_bouton_form_form_connexion} variant="primary" type="submit">
                                Envoyer un courriel de réinitialisation
                                </Button>
                            </Form>
                        </Col>
                        <Col lg="4" xs="1"></Col>
                    </Row>
                }
                {
                    state.etapeActuelle === 2 &&
                    <Row>
                        <Col lg="4" xs="1"></Col>
                        <Col lg="4" xs="10" className="text-center">
                            <Form onSubmit={(event) => {
                                event.preventDefault();
                                verifier_code_renitialisation(state, setState)
                            }}>
                                <Form.Group className="mb-3" controlId="hf_courriel">
                                    <Form.Label className={styles.jl_titre_champ_donnee_form_connexion}>Entrer le code de réinitialisation *</Form.Label>
                                    <Form.Control 
                                        className={styles.jl_champ_donnee_form_connexion}
                                        onChange={(event) => { 
                                            event.preventDefault(); 
                                            setState({
                                                ...state,
                                                codeRenitialisation: event.target.value,
                                                erreurMotDePasseOublier: {
                                                    type: "",
                                                    texte: ""
                                                }
                                            })
                                        }}
                                        value={state.codeRenitialisation}
                                        type="text" 
                                        placeholder="123456" 
                                    />
                                </Form.Group>
                                <Button className={styles.jl_bouton_form_form_connexion} variant="primary" type="submit">
                                Vérifier le code de réinitialisation
                                </Button>
                            </Form>
                        </Col>
                        <Col lg="4" xs="1"></Col>
                    </Row>
                }
                {
                    state.etapeActuelle === 3 &&
                    <Row>
                        <Col lg="4" xs="1"></Col>
                        <Col lg="4" xs="10" className="text-center">
                            <Form onSubmit={(event) => {
                                event.preventDefault();
                                changer_mon_mot_de_passe(state, setState)
                            }}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={styles.jl_titre_champ_donnee_form_connexion}>Entrer votre mot de passe *</Form.Label>
                                    <Form.Control 
                                        className={styles.jl_champ_donnee_form_connexion}
                                        onChange={(event) => { 
                                            event.preventDefault(); 
                                            setState({
                                                ...state,
                                                mot_de_passe: event.target.value,
                                                erreurMotDePasseOublier: {
                                                    type: "",
                                                    texte: ""
                                                }
                                            })
                                        }}
                                        value={state.mot_de_passe}
                                        type="password" 
                                        placeholder="monMotDePa$$e" 
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label className={styles.jl_titre_champ_donnee_form_connexion}>Entrer votre mot de passe à nouveau *</Form.Label>
                                    <Form.Control 
                                        className={styles.jl_champ_donnee_form_connexion}
                                        onChange={(event) => { 
                                            event.preventDefault(); 
                                            setState({
                                                ...state,
                                                nouveau_mot_de_passe: event.target.value,
                                                erreurMotDePasseOublier: {
                                                    type: "",
                                                    texte: ""
                                                }
                                            })
                                        }}
                                        value={state.nouveau_mot_de_passe}
                                        type="password" 
                                        placeholder="monMotDePa$$e" 
                                    />
                                </Form.Group>
                                <Button className={styles.jl_bouton_form_form_connexion} variant="primary" type="submit">
                                Changer mon mot de passe
                                </Button>
                            </Form>
                        </Col>
                        <Col lg="4" xs="1"></Col>
                    </Row>
                }
            </GoogleReCaptchaProvider>
        </>
    );
  }