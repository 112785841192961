import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import styles from "./liste-album.module.css";
import { Link } from 'react-router-dom';
import { obtenir_tous_les_albums } from '../../services/albumService';
import { creerCleUnique } from '../../utilitaires/utilListe';
import GwAlert from '../commun/gw-alert';
import ListePhoto from './liste-photo';

export default function ListeAlbum() {
    const [state, setState] = useState({
        listeAlbums: null
    })

    useEffect(() => {
        !state.listeAlbums && obtenir_tous_les_albums(state, setState);
    }, [state])

    return (
            <div>
                {
                    state.listeAlbums &&
                    state.listeAlbums.length === 0 &&
                    <Row>
                        <Col xs={4}></Col>
                        <Col xs={4}>
                            <GwAlert variant='warning'>Aucun album à afficher.</GwAlert>
                        </Col>
                        <Col xs={4}></Col>
                    </Row>
                }
                {
                    !state.listeAlbums &&
                    <Row className='text-center'>
                        <Col xs={4}></Col>
                        <Col xs={4}>
                            <Spinner></Spinner>
                        </Col>
                        <Col xs={4}></Col>
                    </Row>
                }
                {
                    state.listeAlbums && state.listeAlbums
                    .filter((album) => { return album.est_visible })
                    .map((album) => {
                        return (
                            <Row key={creerCleUnique()}>
                                <Col xs={1}></Col>
                                <Col xs={10}>
                                    <Row>
                                        <Link className={styles.jl_titre_album} to={"/album/" + album.uuid}><h3>Album: {album.nom}</h3></Link>
                                    </Row>
                                    <Row>
                                        <ListePhoto albumUUID={album.uuid}></ListePhoto>
                                    </Row>
                                    </Col>
                                <Col xs={1}></Col>
                            </Row>
                        )
                    })
                }
            </div>
    );
  }