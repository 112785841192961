import React from 'react';
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import TitrePage from '../composants/commun/titre-page';
import { useParams } from 'react-router-dom';
import PiedPage from '../composants/commun/pied-page';
import { obtenirMetaParPathName } from '../utilitaires/utilMeta';
import ListePhoto from '../composants/portfolio/liste-photo';

export default function PageAlbum() {

    let { albumUUID } = useParams();
    const meta = obtenirMetaParPathName("/album/:albumUUID")

    //On créer le rendu de la page connexion
    return (
      <DocumentMeta {...meta}>
        <Container fluid>
            <Navigation></Navigation>
            <TitrePage titre={"Album : " + albumUUID}></TitrePage>
            <ListePhoto avecRow albumUUID={albumUUID}></ListePhoto>
            <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }