import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import { ROUTES } from './config/routes';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      {
        ROUTES.map((route) => {
            return <Route key={route.path} path={route.path} element={ <route.element /> } />
        })
      }
    </Routes>
  </BrowserRouter>
);