import React from 'react';
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import { useState } from 'react';
import PiedPage from '../composants/commun/pied-page';
import TitrePage from '../composants/commun/titre-page';
import ListeAlbum from '../composants/portfolio/liste-album';
import { obtenirMeta } from '../utilitaires/utilMeta';

export default function PagePortfolio() {
    const meta = obtenirMeta(window.location)
    const [state]  = useState({
      meta: meta
    });
    return (
        <DocumentMeta {...state.meta}>
        <Container fluid>
          <Navigation></Navigation>
          <TitrePage titre="Portfolio"></TitrePage>
          <ListeAlbum></ListeAlbum>
          <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }