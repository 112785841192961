import React from 'react';
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import TitrePage from '../composants/commun/titre-page';
import { obtenirMeta } from '../utilitaires/utilMeta';
import PiedPage from '../composants/commun/pied-page';
import FormMotDePasseOublier from '../composants/mot-de-passe-oublier/form-mot-de-passe-oublier';

export default function PageMotDePasseOublier() {

    const meta = obtenirMeta(window.location)
    const [state]  = useState({
      meta: meta
    });
    const navigate = useNavigate();

    //On créer le rendu de la page connexion
    return (
      <DocumentMeta {...state.meta}>
        <Container fluid>
          <Navigation></Navigation>
          <TitrePage titre="Mot de passe oublier"></TitrePage>
          <FormMotDePasseOublier 
            navigate={navigate} 
          />
          <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }