import React from 'react';
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import { useState } from 'react';
import PiedPage from '../composants/commun/pied-page';
import TitrePage from '../composants/commun/titre-page';
import FormContact from '../composants/nous-joindre/form-contact';
import { obtenirMeta } from '../utilitaires/utilMeta';

export default function PageNousJoindre() {
    const meta = obtenirMeta(window.location)
    const [state]  = useState({
      meta: meta
    });
    return (
      <DocumentMeta {...state.meta}>
        <Container fluid>
          <Navigation></Navigation>
          <TitrePage titre="Nous joindre"></TitrePage>
          <FormContact></FormContact>
          <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }