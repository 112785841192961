import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Navbar, Nav, Modal, Form, FormControl, Button, NavDropdown } from 'react-bootstrap';
import styles from './navigation.module.css';
import { Search, BoxArrowInRight, BoxArrowInLeft } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { CONSTANTS } from '../../config/constants';
import { obtenirToken, supprimerToken } from '../../utilitaires/utilToken';

function afficherModaleRecherche(event, state, setState)
{
    event.preventDefault();
    setState({...state, modaleRechercheAffiche: true });
}

function gererChampRecherche(event, state, setState)
{
    event.preventDefault();
    setState({
        ...state,
        rechercheActuelle: event.target.value
    })
}

function lancerRecherche(event, state, setState)
{
    event.preventDefault();
    setState({...state, modaleRechercheAffiche: false });
    window.location = "/recherche?s=" + state.rechercheActuelle
}

export default function Navigation() {
    const [state, setState] = useState({
        modaleRechercheAffiche:false,
        rechercheActuelle:""
    })
    const navigate = useNavigate()
    const token = obtenirToken()
    return (
        <div>
            <Modal 
                show={state.modaleRechercheAffiche} 
                onHide={
                    () => setState({...state, modaleRechercheAffiche: false})
                }
            >
                <Modal.Body>
                    <Form onSubmit={(event) => { lancerRecherche(event, state, setState) }} className='d-flex'>
                        <FormControl
                            onChange={(event) => gererChampRecherche(event, state, setState)} 
                            type='text' 
                            placeholder='Ma recherche' 
                            className={styles.jl_champ_donnee_recherche + " mr-sm-10"}
                            value={state.rechercheActuelle}
                        />
                        <Button className={styles.jl_bouton_recherche} type='submit' disabled={!state.rechercheActuelle}>Rechercher</Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Navbar bg="white" variant="white" expand="xl">
                <Container className={styles.conteneur_navigation}>
                    <Navbar.Brand href="/">
                        <img
                            alt=""
                            className="img-fluid d-inline-block"
                            src={ CONSTANTS.nomDomaineApi + "/images/logo/logo-site-web-jessiel-photographe.png" }
                            width="300"
                            height="50"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto"/>
                        <Nav className='d-flex'>
                            <Nav.Link className={styles.jl_item_navigation} href="/">Accueil</Nav.Link>
                            <Nav.Link className={styles.jl_item_navigation} href="/portfolio">Portfolio</Nav.Link>
                            <Nav.Link className={styles.jl_item_navigation} href="/biographie">Biographie</Nav.Link>
                            <Nav.Link className={styles.jl_item_navigation} href="/tarifs">Tarifs</Nav.Link>
                            <Nav.Link className={styles.jl_item_navigation} href="/nous-joindre">Nous joindre</Nav.Link>
                            {
                                token &&
                                <NavDropdown className={styles.jl_item_navigation} title="Gestion" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/bibliotheque-image">Bibliothèque d'image</NavDropdown.Item>
                                </NavDropdown>
                            }

                            <Nav.Link className={styles.jl_item_navigation} onClick={(event) => { afficherModaleRecherche(event, state, setState) }}>
                                <Search color="black" className={styles.jl_icone}></Search>
                                <div className={styles.jl_texte_icone}>Recherche</div>
                            </Nav.Link>
                            {
                                !token && 
                                <Nav.Link className={styles.jl_item_navigation} href="/connexion">
                                    <BoxArrowInRight color='black' className={styles.jl_icone} />
                                    <div className={styles.jl_texte_icone}>Connexion</div>
                                </Nav.Link>
                            }
                            {
                                token && 
                                <Nav.Link className={styles.jl_item_navigation} onClick={(event) => { 
                                    supprimerToken()
                                    navigate("/")
                                 }}>
                                    <BoxArrowInLeft color='black' className={styles.jl_icone}/>
                                    <div className={styles.jl_texte_icone}>Se déconnecter</div>
                                </Nav.Link>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
  }