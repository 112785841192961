import { ROUTES } from "../config/routes";

export function obtenirMeta(location)
{
    return ROUTES.filter((route) => { return route.path === location.pathname })[0].meta;
}

export function obtenirMetaParPathName(pathname)
{
    return ROUTES.filter((route) => { return route.path === pathname })[0].meta;
}