import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from "./titre-page.module.css";

export default function TitrePage(props) {
    return (
        <Row className={styles.titre_page}>
            <Col md="2"></Col>
            <Col md="8" className="text-center">
              <h2 className={styles.titre_page}>{props.titre}</h2>
            </Col>
            <Col md="2"></Col>
        </Row>
    );
  }