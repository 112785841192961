import { ROUTES } from "../config/routes"

export function rechercher_site_web(valeurRecherche, state, setState)
{
    let listeRouteStatique = ROUTES
    .filter((route) => { 
      return route.path !== "*" && route.path !== "/recherche"
    })
    .filter((route) => { 
      return !route.path.includes(":")
    })
    .filter((route) => { 
      return route.path.includes(valeurRecherche) || route.meta.description.includes(valeurRecherche)
    })
    .map((recherche) => {
        return {
            uuid: recherche.path,
            nom: recherche.meta.title,
            description: recherche.meta.description
        }
    })

    setState({
      ...state,
      listeResultatRecherche: listeRouteStatique
    })

    /*fetch(CONSTANTS.nomDomaineApi + "/recherche/" + valeurRecherche, {
        headers: {
          'Content-Type': 'application/json',
          'Origin':"jessielphotographe.com"
        },
        method: "GET",
        redirect: 'follow'
      })
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result)
            if(result.contenu)
            {
               setState({
                  ...state,
                  listeResultatRecherche: result.contenu.listeResultatRecherche.concat(listeRouteStatique)
              })
            }
          },
          (error) => {
            console.log(error)
          }
        )*/
}