import { CONSTANTS } from "../config/constants"

export function obtenir_tous_les_albums(state, setState)
{
    fetch(CONSTANTS.nomDomaineApi + "/albums", {
      headers: {
        'Content-Type': 'application/json',
        'Origin':"jessielphotographe.com"
      },
      method: "GET",
      redirect: 'follow'
    })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.contenu)
          {
            setState({
                ...state,
                listeAlbums:result.contenu.albums
            })
          }
        },
        (error) => {
          console.log(error)
        }
      )
}

export function obtenir_album_par_uuid(albumUUID, state, setState)
{
  fetch(CONSTANTS.nomDomaineApi + "/albums/" + albumUUID, {
    headers: {
      'Content-Type': 'application/json',
      'Origin':"jessielphotographe.com"
    },
    method: "GET",
    redirect: 'follow'
  })
    .then(res => res.json())
    .then(
      (result) => {
        if(result.contenu)
        {
          let album = result.contenu.album
          setState({
              ...state,
              album: {
                ...album,
                photos:[]
              }
          })
        }
      },
      (error) => {
        console.log(error)
      }
    )
}