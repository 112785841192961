import React from 'react';
import { Alert } from 'react-bootstrap';
import { BugFill, InfoCircleFill } from 'react-bootstrap-icons';
import styles from './gw-alert.module.css';

export default function GwAlert(props) {
    return (
      <Alert className={styles.jl_alerte} variant={props.variant}>
        {
            props.variant === "warning" && <InfoCircleFill></InfoCircleFill>
        }
        {
          props.variant === "danger" && <BugFill></BugFill>
        }
        {props.children}
      </Alert>
    );
}