import { CONSTANTS } from "../config/constants";
import { obtenirToken } from "../utilitaires/utilToken";

export function obtenir_toutes_les_images(state, setState)
{
  fetch(CONSTANTS.nomDomaineApi + "/photos", {
    headers: {
      'Content-Type': 'application/json',
      'Origin':"jessielphotographe.com"
    },
    method: "GET",
    redirect: 'follow'
  })
    .then(res => res.json())
    .then(
      (result) => {
        if(result.contenu)
        {
          setState({
            ...state,
            listeImage: result.contenu.photos
          })
        }
        else
        {
          setState({
            ...state,
            listeImage: []
          })
        }
      },
      (error) => {
        console.log(error)
        setState({
          ...state,
          listeImage: []
        })
      }
    )
}

export function obtenir_les_images_album_portfolio(albumUUID, state, setState)
{
    fetch(CONSTANTS.nomDomaineApi + "/photos/album/" + albumUUID, {
      headers: {
        'Content-Type': 'application/json',
        'Origin':"jessielphotographe.com"
      },
      method: "GET",
      redirect: 'follow'
    })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.contenu)
          {
             let nouvelleListeAlbums = state.listeAlbums.map((album) => {
                if(album.uuid === albumUUID)
                {
                  return({
                      ...album,
                      photos: result.contenu.photos
                  })
                }
                return album
             });
             setState({
                ...state,
                estChercherImage: true,
                listeAlbums: nouvelleListeAlbums
            })
          }
        },
        (error) => {
          console.log(error)
        }
      )
}

export function obtenir_les_images_album(albumUUID, state, setState)
{
    fetch(CONSTANTS.nomDomaineApi + "/photos/album/" + albumUUID, {
      headers: {
        'Content-Type': 'application/json',
        'Origin':"jessielphotographe.com"
      },
      method: "GET",
      redirect: 'follow'
    })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.contenu)
          {
            setState({
              ...state,
              listePhoto: result.contenu.photos
            })
            return;
          }
          setState({
            ...state,
            listePhoto: []
          })
        },
        (error) => {
          console.log(error)
          setState({
            ...state,
            listePhoto: []
          })
        }
      )
}

export function obtenir_image_par_uuid(imageUUID, state, setState)
{
    fetch(CONSTANTS.nomDomaineApi + "/photos/" + imageUUID, {
      headers: {
        'Content-Type': 'application/json',
        'Origin':"jessielphotographe.com"
      },
      method: "GET"
    })
      .then(
        async (response) => {

          let responseJSON = await response.json();

          if(response.status === 200)
          {
            setState({
                ...state,
                image: responseJSON.contenu.photo
            })
          }
          else
          {
            setState({
                ...state,
                image: null
            })
          }
        },
        (error) => {
          console.log(error)
          setState({
              ...state,
              image: null
          })
        }
      )
}

export function ajouter_image(file, setStateListeImage)
{
  let token = obtenirToken();
  let formData = new FormData()
  formData.append('img', file)

  fetch(CONSTANTS.nomDomaineApi + "/photos/ajouter", {
    headers: {
      'Origin':"jessielphotographe.com",
      'Authorization': 'Bearer ' + token
    },
    method: 'POST',
    body: formData
  })
      .then(
          (res) => { 
              setStateListeImage({
                  listeImage: null
              }) 
          }
      )
      .catch(
          (error) => { 
              console.log(error) 
          }
      )
}

export function modifier_image(imageUUID, state, setState)
{
  if(!state.image.nom)
  {
    setState({
      ...state,
      erreurModificationImage: {
          type: "warning",
          texte: "Veuillez remplir le nom de l'image."
      }
    })
    return;
  }
  if(!state.image.description)
  {
    setState({
      ...state,
      erreurModificationImage: {
          type: "warning",
          texte: "Veuillez remplir la description de l'image."
      }
    })
    return;
  }

  let token = obtenirToken()
  fetch(CONSTANTS.nomDomaineApi + "/photos/modifier/" + imageUUID, {
    headers: {
      'Content-Type': 'application/json',
      'Origin':"jessielphotographe.com",
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(state.image),
    method: "POST"
  })
    .then(
      async (response) => {
        if(response.status === 200)
        {
          setState({
            ...state,
            erreurModificationImage: {
                type: "success",
                texte: "L'image a été modifié."
            }
          })
        }
        else
        {
          setState({
            ...state,
            erreurModificationImage: {
                type: "danger",
                texte: "Une erreur inattendue est survenue."
            }
          })
        }
      },
      (error) => {
        console.log(error)
        setState({
          ...state,
          erreurModificationImage: {
              type: "danger",
              texte: "Une erreur inattendue est survenue."
          }
        })
      }
    )
}

export function supprimer_image(imageUUID, setStateListeImage)
{
  let token = obtenirToken()
  fetch(CONSTANTS.nomDomaineApi + "/photos/supprimer/" + imageUUID, {
    headers: {
      'Content-Type': 'application/json',
      'Origin':"jessielphotographe.com",
      'Authorization': 'Bearer ' + token
    },
    method: "DELETE"
  })
    .then(res => res.json())
    .then(
      (result) => {
        setStateListeImage({
          listeImage: null
        })
        return;
      },
      (error) => {
        setStateListeImage({
          listeImage: null
        })
        console.log(error)
      }
    )
}