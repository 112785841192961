import React from 'react';
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState } from 'react';
import styles from "./page.module.css";
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PiedPage from '../composants/commun/pied-page';
import { CONSTANTS } from '../config/constants';
import { obtenirMetaParPathName } from '../utilitaires/utilMeta';

export default function PageNonTrouve() {
    const meta = obtenirMetaParPathName("*")
    const [state]  = useState({
      meta: meta
    });
    return (
        <DocumentMeta {...state.meta}>
        <Container fluid>
          <Navigation></Navigation>
          <Row>
            <Col xs="4"></Col>
            <Col xs="4">
              <img
                className={ styles.jl_image_pas_non_trouve + " img-fluid" }
                src={ CONSTANTS.nomDomaineApi + '/images/divers/homme-tenant-une-loupe-representant-le-site-web-qui-recherche-une-page.jpg' }
                alt="Représente que le site web n'a pas trouvé la page web"
              />
            </Col>
            <Col xs="4"></Col>
          </Row>
          <Row>
            <Col xs="2"></Col>
            <Col xs="8" className="text-center">
              <h2 className={styles.titre_page}>Page non trouvé</h2>
              <Link to="/"><Button className={styles.bouton}>Retourner à la page d'accueil</Button></Link>
            </Col>
            <Col xs="2"></Col>
          </Row>
          <PiedPage></PiedPage>
        </Container>
      </DocumentMeta>
    );
  }