import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styles from './form-connexion.module.css';
import GwAlert from '../commun/gw-alert';
import { connexion } from '../../services/authentificationService';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { CONSTANTS } from '../../config/constants';

export default function FormConnexion(props) {

    const [state, setState] = useState({
        analyzedUserRecaptcha: false,
        identifiantsConnexion : {
            recaptcha_token: "",
            courriel: "",
            mot_de_passe: "",
        },
        erreurConnexion: ""
    }, [])

    return (
        <>
            <Row>
                <Col xs="4"></Col>
                <Col xs="4" className="text-center">
                {
                    state.erreurConnexion && <GwAlert variant="danger">{state.erreurConnexion}</GwAlert>
                }
                </Col>
                <Col xs="4"></Col>
            </Row>
            <Row>
                <Col lg="4" xs="1"></Col>
                <Col lg="4" xs="10" className="text-center">
                    <GoogleReCaptchaProvider
                        reCaptchaKey={CONSTANTS.cleRecaptcha}
                        language='fr-CA'
                        scriptProps={{
                            async: true
                        }}
                    >
                        <GoogleReCaptcha onVerify={ (recaptcha_token) => {
                            if(!state.analyzedUserRecaptcha)
                            {
                                setState({
                                    ...state,
                                    analyzedUserRecaptcha: true,
                                    identifiantsConnexion: {
                                        ...state.identifiantsConnexion,
                                        recaptcha_token: recaptcha_token
                                    }
                                })
                            }
                        }} />
                        <Form onSubmit={(event) => { 
                            event.preventDefault();
                            connexion(props.navigate, state, setState)
                        }}>
                            <Form.Group className="mb-3" controlId="hf_courriel">
                                <Form.Label className={styles.jl_titre_champ_donnee_form_connexion}>Votre email *</Form.Label>
                                <Form.Control 
                                    className={styles.jl_champ_donnee_form_connexion}
                                    onChange={(event) => {
                                        setState({
                                            ...state,
                                            identifiantsConnexion: {
                                                ...state.identifiantsConnexion,
                                                courriel: event.target.value
                                            },
                                            erreurConnexion: ""
                                        }) 
                                    }} 
                                    type="email" 
                                    placeholder="exemple@jessielphotographe.com" 
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="hf_mot_de_passe">
                                <Form.Label className={styles.jl_titre_champ_donnee_form_connexion}>Votre mot de passe *</Form.Label>
                                <Form.Control
                                    className={styles.jl_champ_donnee_form_connexion}
                                    onChange={(event) => { 
                                        setState({
                                            ...state,
                                            identifiantsConnexion: {
                                                ...state.identifiantsConnexion,
                                                mot_de_passe: event.target.value
                                            },
                                            erreurConnexion: ""
                                        }) 
                                    }} 
                                    type="password" 
                                    placeholder="exemple@#!motPAsse" 
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="hf_mot_de_passe">
                                <a href='/mot-de-passe-oublier'>J'ai oublié mon mot de passe</a>
                            </Form.Group>
                            <Button className={styles.jl_bouton_form_form_connexion} variant="primary" type="submit">
                            Me connecter
                            </Button>
                        </Form>
                    </GoogleReCaptchaProvider>
                </Col>
                <Col lg="4" xs="1"></Col>
            </Row>
            
        </>
    );
  }