import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import styles from './form-contact.module.css';
import Form from 'react-bootstrap/Form';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import GwAlert from '../commun/gw-alert';
import { CONSTANTS } from '../../config/constants';
import { envoyer_courriel_contact } from '../../services/courrielService';

export default function FormContact() {

    const [state, setState] = useState({
        erreurCourriel:"",
        courriel: {
            recaptcha_token: "",
            prenom:"",
            nom:"",
            courriel:"",
            numero_telephone:"",
            sujet:"",
            message:"",
            est_volontaire_infolettre:false
        }
    })

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={CONSTANTS.cleRecaptcha}
            language='fr-CA'
            scriptProps={{
                async: true
            }}
        >
            <GoogleReCaptcha 
                onVerify={ (recaptchaToken) => {
                    if(!state.analyzedUserRecaptcha)
                    {
                        setState({
                            ...state,
                            analyzedUserRecaptcha: true,
                            courriel: {
                                ...state.courriel,
                                recaptcha_token: recaptchaToken
                            }
                        })
                    } 
                }} 
            />
            <Row className="text-center">
                <Col lg={2}></Col>
                <Col lg={4}>
                    <Row className={styles.jl_reseau_sociaux_nous_joindre}>
                        <h2 className={styles.jl_titre_nous_joindre}>Notre courriel</h2>
                        <p>info@jessielphotographe.com</p>
                        <h2 className={styles.jl_titre_nous_joindre}>Nos réseaux sociaux</h2>
                        <Col xs={4}></Col>
                        <Col xs={2}>
                            <a target="_blank" rel="noreferrer" href='https://www.facebook.com/JessieLPhotographe'>
                                <img
                                    alt="Aller sur la page Facebook JessieL Photographe."
                                    className='img-fluid'
                                    src={ CONSTANTS.nomDomaineApi + '/images/logo-tiers/facebook.png' }
                                />
                            </a>
                        </Col>
                        <Col xs={2}>
                            <a target="_blank" rel="noreferrer" href='https://www.instagram.com/jessielphotographe'>
                                <img
                                    alt="Aller sur la page Instagram JessieL Photographe."
                                    className='img-fluid'
                                    src={ CONSTANTS.nomDomaineApi + '/images/logo-tiers/instagram.png' }
                                />
                            </a>
                        </Col>
                        <Col xs={4}></Col>
                    </Row>
                </Col>
                <Col lg={4}>
                    <h2 className={styles.jl_titre_nous_joindre}>Écrivez nous un message</h2>
                    <Form 
                        className={styles.jl_form_message}
                        onSubmit={(event) => {
                            event.preventDefault();
                            envoyer_courriel_contact(state, setState)
                        }}
                    >
                        {
                            state.erreurCourriel && <GwAlert variant='warning'>{state.erreurCourriel}</GwAlert>
                        }
                        <Form.Group className="mb-3" controlId="jl_prenom">
                            <Form.Label className={styles.jl_titre_champ_donnee_nous_joindre}>Prénom *</Form.Label>
                            <Form.Control 
                                value={state.courriel.prenom} 
                                onChange={(event) => setState({
                                    ...state,
                                    erreurCourriel:"",
                                    courriel:{
                                        ...state.courriel,
                                        prenom: event.target.value
                                    }
                                })}
                                className={styles.jl_champ_donnee_nous_joindre} 
                                type="text" 
                                placeholder="John" 
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="jl_nom">
                            <Form.Label className={styles.jl_titre_champ_donnee_nous_joindre}>Nom *</Form.Label>
                            <Form.Control 
                                value={state.courriel.nom}
                                onChange={(event) => setState({
                                    ...state,
                                    courriel:{
                                        ...state.courriel,
                                        nom: event.target.value
                                    }
                                })}
                                className={styles.jl_champ_donnee_nous_joindre} 
                                type="text" 
                                placeholder="Doe" 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="jl_courriel">
                            <Form.Label className={styles.jl_titre_champ_donnee_nous_joindre}>Adresse courriel*</Form.Label>
                            <Form.Control 
                                value={state.courriel.courriel}
                                onChange={(event) => setState({
                                    ...state,
                                    courriel:{
                                        ...state.courriel,
                                        courriel: event.target.value
                                    }
                                })}
                                className={styles.jl_champ_donnee_nous_joindre} 
                                type="text" 
                                placeholder="johndoe@domaine.com" 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="jl_telephone">
                            <Form.Label className={styles.jl_titre_champ_donnee_nous_joindre}>Numéro de téléphone*</Form.Label>
                            <Form.Control 
                                value={state.courriel.numero_telephone}
                                onChange={(event) => setState({
                                    ...state,
                                    courriel:{
                                        ...state.courriel,
                                        numero_telephone: event.target.value
                                    }
                                })}
                                className={styles.jl_champ_donnee_nous_joindre} 
                                type="tel" 
                                placeholder="999-999-9999" 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="jl_sujet">
                            <Form.Label className={styles.jl_titre_champ_donnee_nous_joindre}>Sujet*</Form.Label>
                            <Form.Control 
                                value={state.courriel.sujet}
                                onChange={(event) => setState({
                                    ...state,
                                    courriel:{
                                        ...state.courriel,
                                        sujet: event.target.value
                                    }
                                })}
                                className={styles.jl_champ_donnee_nous_joindre} 
                                type="text" 
                                placeholder="Ma superbe idée photo" 
                                />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId='jl_message'>
                            <Form.Label className={styles.jl_titre_champ_donnee_nous_joindre}>Votre message*</Form.Label>
                            <Form.Control
                                value={state.courriel.message}
                                onChange={(event) => setState({
                                    ...state,
                                    courriel:{
                                        ...state.courriel,
                                        message: event.target.value
                                    }
                                })}
                                className={styles.jl_champ_donnee_nous_joindre} 
                                as="textarea" 
                                aria-label="Zone de texte pour votre message." 
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="jl_infolettre">
                            <Form.Check 
                                value={state.courriel.est_volontaire_infolettre}
                                onChange={(event) => setState({
                                    ...state,
                                    courriel:{
                                        ...state.courriel,
                                        est_volontaire_infolettre: event.target.checked
                                    }
                                })}
                                className={styles.jl_champ_donnee_nous_joindre} 
                                type="checkbox" 
                                label="M'inscrire à l'infolettre" 
                            />
                        </Form.Group>
                        <Button className={styles.jl_bouton_form_nous_joindre} variant="primary" type="submit">
                            Envoyer message
                        </Button>
                    </Form>
                </Col>
                <Col lg={2}></Col>
            </Row>
        </GoogleReCaptchaProvider>
    );
  }