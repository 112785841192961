import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import styles from './liste-tarifs.module.css';
import { Link } from 'react-router-dom';
import { CONSTANTS } from '../../config/constants';

export default function ListeTarifs() {
    return (
        <Row className={styles.jl_conteneur_texte_biographie + " text-center"}>
            <Col lg={1}></Col>
            <Col lg={3}>
                <h2 className={styles.jl_titre_tarif}>Séance régulière</h2>
                <img
                    className={styles.jl_image_tarif_secondaire + " img-fluid" }
                    src={ CONSTANTS.nomDomaineApi + '/images/tarifs/homme-tenant-une-coupe-de-vin.jpg' }
                    alt="Homme tenant une coupe de vin."
                />
                <p className={styles.jl_texte_tarif}>La séance régulière est géniale si vous voulez avoir quelques portraits différents de vous, que ce soit seul ou accompagné. Celle-ci comprend vos 10 photos préférées remises retouchées, en formats numériques et envoyées via Wetransfert pour utilisation personnelle. En studio, à l'extérieur ou à l'endroit de votre choix, c'est votre petit moment à vous. Certains frais de déplacements peuvent s'appliquer, selon la région.</p>
                <Link to="/nous-joindre"><Button className={styles.jl_bouton_tarif}>Prendre contact avec nous</Button></Link>
            </Col>
            <Col lg={4}>
                <h2 className={styles.jl_titre_tarif}>Séance sur mesure</h2>
                <img
                    className={styles.jl_image_tarif_primaire + " img-fluid" }
                    src={ CONSTANTS.nomDomaineApi + '/images/tarifs/femme-affaires-tenant-un-telephone.jpg' }
                    alt="Femme d'affaires tenant un téléphone."
                />
                <p className={styles.jl_texte_tarif}>Si mes forfaits ne correspondent pas à vos besoins, veuillez me contacter pour une soumission sur mesure, il me fera grandement plaisir d'en discuter avec vous. Nous allons trouver la formule idéale, j'en suis certaine!</p>
                <Link to="/nous-joindre"><Button className={styles.jl_bouton_tarif}>Prendre contact avec nous</Button></Link>
            </Col>
            <Col lg={3}>
                <h2 className={styles.jl_titre_tarif}>Séance deluxe</h2>
                <img
                    className={styles.jl_image_tarif_secondaire + " img-fluid" }
                    src={ CONSTANTS.nomDomaineApi + '/images/tarifs/personne-dans-une-lingerie-de-style-boudoir.jpg' }
                    alt="Personne dans de la lingerie de style boudoir."
                />
                <p className={styles.jl_texte_tarif}>La séance Deluxe est l'idéal si vous avez envie d'avoir plusieurs portraits différents de votre belle petite face, que ce soit seul ou accompagné. Ça peut être une belle occasion pour des photos de famille. Celle-ci comprend vos 15 photos préférées remises retouchées, en formats numériques et envoyées via Wetransfert pour utilisation personnelle. En studio, à l'extérieur ou à l'endroit de votre choix, l'important c'est que vous soyez bien. Certains frais de déplacements peuvent s'appliquer, selon la région.</p>
                <Link to="/nous-joindre"><Button className={styles.jl_bouton_tarif}>Prendre contact avec nous</Button></Link>
            </Col>
            <Col lg={1}></Col>
        </Row>
    );
  }