import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import styles from './zone-accueil-avis.module.css';
import { Quote } from 'react-bootstrap-icons';
import { obtenir_avis } from '../../services/avisService';
import { CONSTANTS } from '../../config/constants';

function incrementerCompteur(state, setState)
{
    if(state.compteurAvis < state.listeAvis.length - 1) setState({...state, compteurAvis: state.compteurAvis + 1})
    else setState({...state, compteurAvis: 0})
}

function decrementerCompteur(state, setState)
{ 
    if(state.compteurAvis >= 1) setState({...state, compteurAvis: state.compteurAvis - 1})
    else setState({...state, compteurAvis: state.listeAvis.length - 1})
}

export default function ZoneAccueilAvis() {
    const [state, setState] = useState({
        compteurAvis: 0,
        listeAvis: []
    })
    !state.listeAvis.length && obtenir_avis(state, setState)
    return (
        state.listeAvis.length && 
        <Container 
            className={styles.jl_conteneur_accueil_avis}
            style={{
                backgroundImage: `url(${CONSTANTS.nomDomaineApi}/images/banniere/groupe-de-personne-a-un-concert.jpg)`
            }}    
        >
            <Row>
                <Col xs={12} className="text-center">
                    <h3 className={styles.jl_accueil_avis_titre}>Les avis de nos clients</h3>
                </Col>
            </Row>
            <Row className={styles.jl_accueil_avis_alignement}>
                <Col lg={1}></Col>
                <Col lg={2} className="text-center">
                    <Button onClick={() => { decrementerCompteur(state, setState) }} className={styles.jl_accueil_avis_bouton}>PREV.</Button>
                </Col>
                <Col lg={6}>
                    <div className={styles.jl_accueil_avis_texte}>  
                        <p className='text-center'><Quote></Quote></p>
                        <p>{ state.listeAvis[state.compteurAvis].texte }</p>
                        <p>- { state.listeAvis[state.compteurAvis].nom_complet}</p>
                    </div>
                </Col>
                <Col lg={2} className="text-center">
                    <Button onClick={() => { incrementerCompteur(state, setState) }} className={styles.jl_accueil_avis_bouton}>SUIV.</Button>
                </Col>
                <Col lg={1}></Col>
            </Row>
        </Container>
    );
  }