import React from 'react';
import DocumentMeta from 'react-document-meta';
import { useState } from 'react';
import Navigation from '../composants/commun/navigation';
import CarrouselPrincipal from '../composants/accueil/carrousel-principal';
import ZoneAccueilAPropos from '../composants/accueil/zone-accueil-a-propos';
import ZoneAccueilListeAlbum from '../composants/accueil/zone-accueil-liste-album';
import ZoneAccueilAvis from '../composants/accueil/zone-accueil-avis';
import PiedPage from '../composants/commun/pied-page';
import { obtenirMeta } from '../utilitaires/utilMeta';

function PageAccueil() {
  const meta = obtenirMeta(window.location)
  const [state]  = useState({
    meta: meta
  });
  return (
    <DocumentMeta {...state.meta}>
      <Navigation></Navigation>
      <CarrouselPrincipal></CarrouselPrincipal>
      <ZoneAccueilAPropos></ZoneAccueilAPropos>
      <ZoneAccueilListeAlbum></ZoneAccueilListeAlbum>
      <ZoneAccueilAvis></ZoneAccueilAvis>
      <PiedPage></PiedPage>
    </DocumentMeta>
  );
}

export default PageAccueil;