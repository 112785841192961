import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CONSTANTS } from '../../config/constants';
import { ENUM_TYPE_IMAGE } from '../../enums/enum-type-image';
import styles from "./image.module.css";

function obtenir_longueur_colonne_md(type_image, aucunLien)
{
    if(aucunLien) return "8";
    if(type_image === ENUM_TYPE_IMAGE.CARRE) return "2"
    if(type_image === ENUM_TYPE_IMAGE.PORTRAIT) return "2"
    return "4"
}

function obtenir_longueur_colonne_sm(type_image, aucunLien)
{
    if(aucunLien) return "10";
    if(type_image === ENUM_TYPE_IMAGE.CARRE) return "6"
    if(type_image === ENUM_TYPE_IMAGE.PORTRAIT) return "6"
    return "6"
}

export default function Image(props) {
    return (
        <Col 
            xs={obtenir_longueur_colonne_sm(props.type_image, props.aucunLien)}
            md={obtenir_longueur_colonne_md(props.type_image, props.aucunLien)}
        >
            {
                props.type_image === ENUM_TYPE_IMAGE.CARRE && props.aucunLien &&
                <img
                    alt={props.description}
                    className={"img-fluid " + styles.jl_image_sans_lien}
                    src={ CONSTANTS.nomDomaineApi + "/images/portfolio/" + props.uuid + "." + props.extension}
                />
            }
            {
                props.type_image === ENUM_TYPE_IMAGE.RECTANGLE && props.aucunLien &&
                <img
                    alt={props.description}
                    className={"img-fluid " + styles.jl_image_sans_lien}
                    src={CONSTANTS.nomDomaineApi + "/images/portfolio/" + props.uuid + "." + props.extension}
                />
            }
            {
                props.type_image === ENUM_TYPE_IMAGE.PORTRAIT && props.aucunLien &&
                <img
                    alt={props.description}
                    className={"img-fluid " + styles.jl_image_sans_lien}
                    src={CONSTANTS.nomDomaineApi + "/images/portfolio/" + props.uuid + "." + props.extension}
                />
            }
            {
                props.type_image === ENUM_TYPE_IMAGE.CARRE && !props.aucunLien &&
                <Link to={"/image/" + props.uuid}>
                    <img
                        alt={props.description}
                        className={styles.jl_image + " img-fluid"}
                        src={CONSTANTS.nomDomaineApi + "/images/portfolio/" + props.uuid + "." + props.extension}
                    />
                </Link>
            }
            {
                props.type_image === ENUM_TYPE_IMAGE.RECTANGLE && !props.aucunLien &&
                <Link to={"/image/" + props.uuid}>
                    <img
                        alt={props.description}
                        className={styles.jl_image + " img-fluid"}
                        src={CONSTANTS.nomDomaineApi + "/images/portfolio/" + props.uuid + "." + props.extension}
                    />
                </Link>
            }
            {
                props.type_image === ENUM_TYPE_IMAGE.PORTRAIT && !props.aucunLien &&
                <Link to={"/image/" + props.uuid}>
                    <img
                        alt={props.description}
                        className={styles.jl_image + " img-fluid"}
                        src={CONSTANTS.nomDomaineApi + "/images/portfolio/" + props.uuid + "." + props.extension}
                    />
                </Link>
            }
        </Col>
    );
  }