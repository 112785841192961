import React from 'react';
import { Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CONSTANTS } from '../../config/constants';
import styles from './carrousel-principal.module.css';

export default function CarrouselPrincipal() {
    return (
    <Carousel className={styles.conteneur_carrousel}>
        <Carousel.Item className={styles.image_carrousel}>
            <img
                className="d-block w-100"
                src={ CONSTANTS.nomDomaineApi + "/images/carrousel/personnes-ayant-participer-a-un-projet-portant-sur-la-couleur.jpg" }
                alt="First slide"
            />
            <Carousel.Caption className={styles.jl_zone_texte_carrousel}>
                <h1 className={styles.jl_titre_zone_texte_carrousel}>BIENVENUE CHEZ JESSIEL PHOTOGRAPHE</h1>
                <Link to="/nous-joindre"><Button className={styles.jl_bouton_zone_texte_carrousel}>Nous joindre</Button></Link>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className={styles.image_carrousel}>
            <img
                className="d-block w-100"
                src={ CONSTANTS.nomDomaineApi + "/images/carrousel/deux-filles-portant-un-style-gothique.jpg" }
                alt="Second slide"
            />
            <Carousel.Caption className={styles.jl_zone_texte_carrousel}>
                <h1 className={styles.jl_titre_zone_texte_carrousel}>VOUS AVEZ DES QUESTIONS ?</h1>
                <Link to="/nous-joindre"><Button className={styles.jl_bouton_zone_texte_carrousel}>Prendre contact</Button></Link>
            </Carousel.Caption>
        </Carousel.Item>
    </Carousel>
    );
  }