import React, { useState } from 'react';
import DocumentMeta from 'react-document-meta';
import Container from 'react-bootstrap/Container';
import Navigation from '../composants/commun/navigation';
import TitrePage from '../composants/commun/titre-page';
import { useSearchParams } from 'react-router-dom';
import PiedPage from '../composants/commun/pied-page';
import { obtenirMeta } from '../utilitaires/utilMeta';
import ResultatsRecherche from '../composants/recherche/resultat-recherche';

export default function PageRecherche() {

    //Création des constantes de la page connexion
    const meta = obtenirMeta(window.location)
    const [searchParams] = useSearchParams();
    const [state]  = useState({
      meta: meta
  });

    //On créer le rendu de la page connexion
    return (
      <DocumentMeta {...state.meta}>
        <Container fluid>
            <Navigation/>
            <TitrePage titre={"Résultats de recherche pour : " + searchParams.get("s")}/>
            <ResultatsRecherche/>
            <PiedPage/>
        </Container>
      </DocumentMeta>
    );
  }