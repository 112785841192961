import React, { useRef } from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './zone-depot-image.module.css';
import { ajouter_image } from '../../services/photoService';

export default function ZoneDepotImage(props) {
    const [state, setState] = useState({
        isHovering: false
    })
    const refFileSelect = useRef();

    function handleFiles(files) {
        ([...files]).forEach((file) => {
            ajouter_image(file, props.setStateListeImage)
        })
    }

    return (
        <Row className='text-center'>
            <Col xs={2}></Col>
            <Col xs={8}>
                <div
                    className={!state.isHovering ? styles.zone_depot_image : styles.zone_depot_image_couleur}
                    onClick={() => {
                        refFileSelect.current.click()
                    }}
                    onDrop={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setState({
                            isHovering: false
                        })
                        let dt = e.dataTransfer
                        let files = dt.files
                        handleFiles(files)
                    }}
                    onDragOver={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setState({
                            isHovering: true
                        })
                    }}
                    onDragLeave={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setState({
                            isHovering: false
                        })
                    }}
                    onDragEnter={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setState({
                            isHovering: true
                        })
                    }}
                >
                    <form 
                        className={styles.conteneur_selection_fichier}
                    >
                        <input 
                            type="file" 
                            className={styles.file_element} 
                            multiple 
                            accept="image/*" 
                            ref={refFileSelect}
                            onChange={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                let files = e.target.files;
                                handleFiles(files)
                            }}
                        />
                        <p className={styles.texte_depot_image}>Déposer un ou plusieurs images dans <i>cette zone</i>.</p>
                    </form>
                </div>
            </Col>
            <Col xs={2}></Col>
        </Row>
    );
  }